<script>
    import { spring } from 'svelte/motion';
    import { onMount } from 'svelte';

    const tabs = [
        { id: '1', label: 'Tab 1' },
        { id: '2', label: 'Tab 2' },
        { id: '3', label: 'Tab 3' },
    ];

    let activeTab = '1';
    let tabsContainer;
    let borderSpring;

    onMount(() => {
        borderSpring = spring(
            { left: 0, width: 0 },
            {
                stiffness: 1,
                damping: 1,
            }
        );
        setActiveTab(activeTab);
    });

    function setActiveTab(id) {
        activeTab = id;
        const activeElement = tabsContainer.querySelector(`[data-tab="${id}"]`);
        if (activeElement) {
            borderSpring.set({
                left: activeElement.offsetLeft,
                width: activeElement.offsetWidth,
            });
        }
    }
</script>

<div class="w-fixed px-30 relative mt-5 flex flex-row justify-around" bind:this="{tabsContainer}">
    {#each tabs as tab}
        <button
            class="cursor-pointer pb-2 dark:text-gray-200"
            class:selected="{activeTab === tab.id}"
            on:click="{() => setActiveTab(tab.id)}"
            data-tab="{tab.id}"
        >
            {tab.label}
        </button>
    {/each}
    {#if borderSpring}
        <div
            class="absolute bottom-0 h-0.5 bg-bmn-500 transition-all duration-300"
            style="left: {$borderSpring.left}px; width: {$borderSpring.width}px;"
        ></div>
    {/if}
</div>

<style>
    .selected {
        color: aquamarine;
    }
</style>
